<template>
  <a-card class="card rent" :bordered="false">
    <base-page
      :queryFields="queryFields"
      :columns="columns"
      :rules="rules"
      :modelTitle="modelTitle"
      :formFields="formFields"
      :moduleName="moduleName"
      :getPageFun="getWmsMetaPage"
      :deleteFun="deleteWmsMetaInfo"
      :createFun="createWmsMetaInfo"
      :updateFun="updateWmsMetaInfo">
    </base-page>
  </a-card>
</template>
<script>
import { BasePage } from '@/components'
import { getWmsMetaPage, createWmsMetaInfo, updateWmsMetaInfo, deleteWmsMetaInfo } from '@/api/wms'
export default {
  components: {
    BasePage
  },
  data() {
    return {
      tenantOps: [],
      queryFields: [
        { label: '业务类型', name: 'name', fieldType: '', queryType: '%' }
      ],
      columns: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' },
          width: 30
        },
        {
          title: '业务类型',
          dataIndex: 'name'
        },
        {
          title: '编码',
          dataIndex: 'code'
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: 120,
          scopedSlots: { customRender: 'action' }
        }
      ],
      rules: {
        name: [
          { required: true, message: '请填写业务类型', trigger: 'blur' }
        ]
      },
      modelTitle: '业务类型',
      formFields: [
        {
          label: '业务类型', name: 'name', type: 'default', ref: 'type'
        },
        {
          label: '编码', name: 'code', type: 'default'
        }
      ],
      moduleName: 'wms_business_type'
    }
  },
  methods: {
    getWmsMetaPage,
    createWmsMetaInfo,
    updateWmsMetaInfo,
    deleteWmsMetaInfo
  }
}
</script>

<style>
  .rent .ant-form .ant-col-12{
    float: right
  }
</style>
